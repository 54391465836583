<template>
  <div v-if="$hasFonction(user, 'Voir véhicule')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="container-filter-export row justify-content-between h-25">
          <div class="col-lg-2 col-md-3 mb-1">       
            <b-form-input
              v-model="startSellDateFilterValue"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              />     
            <label>Vendu du :</label>
            <span class="text-danger">
              {{ errorValueStart }}
            </span>
          </div>

          <div class="col-md-3 col-lg-2 mb-1" >
            <b-form-input
              v-model="endSellDateFilterValue"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              />
              <label>Au : </label>
          </div>

          <!-- solde start -->
          <div id="solde-input" class="col-md-3 col-lg-2 mb-1" >
            <b-input-group prepend="Bénéfice">
              <b-form-input
                v-model="benefice"
                type="text"
                disabled
                placeholder="Bénéfice"
              >
              </b-form-input>
            </b-input-group>
          </div>
          <!-- solde end -->

            <div class="col-lg-2 col-md-3">
              <b-button
              variant="outline-primary"
              size="sm"
              style="height: 40px"
              @click="ExportFomatExcel()"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Exporter
              </b-button>
            </div>

            <div class="col-lg-2 col-md-3">
              <b-button
              variant="outline-primary"
              size="sm"
              class="mt-2 mt-lg-0"
              style="height: 40px"
              @click="ExportAllToFomatExcel()"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Tout Exporter
              </b-button>
            </div>
           
          
          <!-- bouton export end -->
        </div>

        <b-card id="block-card" no-body title="" class="mt-1">
          <div class="my-2 mx-1 row">
            <!-- search start -->
            <div class="col-md-3 col-lg-2 mb-1">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Recherche"
                @input="filterValue"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- date start -->
            <div class="col-md-3 col-lg-2 mb-1">
                <b-form-input
              placeholder="Date de début"
              v-model="valueStart"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue"
              />
              <label>Date de début</label>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>
            
            <div class="col-md-3 col-lg-2 mb-1">
                <b-form-input
              placeholder="Date de fin"
              v-model="valueEnd"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue"
              />
              <label>Date de fin</label>
            </div>
            <!-- date end -->
            <!-- statut start -->
            <div class="col-md-3 col-lg-2 mb-1">
              <b-form-select
                  ref="status_filter"
                  v-model="selectedStatut"
                  :options="statut_options"
                  @change="changestatut"
                  @input="filterValue"
              >
              </b-form-select>
            </div>
            <!-- statut end -->

            <!-- select association start -->
            <div class="col-md-3 col-lg-2 mb-1">
              <b-form-select
                v-model="selectedAssociation"
                :options="association_options"
                value-field="name"
                text-field="name"
                @input="filterValue"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Association --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>

            <div id="solde-input" class="col-md-3 col-lg-2">
              <b-input-group>
                <b-form-input
                  v-model="valeurStock"
                  type="text"
                  disabled
                  placeholder="Valeur du stock"
                >
                </b-form-input>
              </b-input-group>
            </div>
            <!-- select importateur end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="excelVehicule"
            id="my-table"
            responsive
            :fields="headers"
            :items="vehicules"
            :per-page="perPage"
            hover
            striped
            @row-clicked="onRowClicked"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
            :tbody-tr-class="itemRowBackground"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >

          

            <template #cell(date_arrivee)="data">
              <span>
                 {{
                  (data.item&&data.item.date_arrivee)
                      ? $formatDate(data.item.date_arrivee)
                      : (data.item&&data.item.manifeste&&data.item.manifeste.date_arrivee)
                          ? data.item.manifeste.date_arrivee
                          : "----"
                }}

              </span>
            </template>

            <template   #cell(numero_manif)="data">
              <b-button size="sm" variant="info" @click="goto(data.item)">
                {{ data.item.manifeste.numero_manif }}
              </b-button>
            </template>

            <template #cell(total_dep)="data">
              {{
                data.item.total_dep
                  ? $thousandSeparator(data.item.total_dep)
                  : "-----"
              }}
            </template>
            <template #cell(total_achat)="data">  {{
                data.item.conv_achat
                    ? $thousandSeparator(data.item.total_achat)
                    : "-----"
              }}
            </template>
            <template #cell(prix_revient)="data">
              {{
                data.item.prix_revient
                  ? $thousandSeparator(data.item.prix_revient)
                  : "-----"
              }}
            </template>
            <template #cell(benefice)="data">
              {{
                data.item.benefice
                  ? $thousandSeparator(data.item.benefice)
                  : "-----"
              }}
            </template>
            <template #cell(prix_vente)="data">
              <b-badge
                v-if="
                  data.item.statut == 'vendu' ||
                  data.item.statut == 'en cours de vente'
                "
                bordered
                left
                top
                dot
              ></b-badge>
              {{
                data.item.prix_vente
                  ? $thousandSeparator(data.item.prix_vente)
                  : "-----"
              }}
            </template>

            <template #cell(statut)="data">
              <b-badge
                class="white--text"
                :variant="
                  data.item.statut == 'en stock'
                    ? 'primary'
                    : data.item.statut == 'en dépôt'
                    ? 'danger'
                    : data.item.statut == 'en attente'
                    ? 'warning'
                    : data.item.statut == 'en cours de vente'
                    ? 'secondary'
                    : data.item.statut == 'vendu'
                    ? 'primary'
                    : 'success'
                "
                small
              >
                {{ data.item.statut }}
               </b-badge>
              
            </template>

            <template #cell(association)="data">
              <a
                  class="hoverSuccess"
                  @click="showAssociation(data.item.manifeste.association)">
                {{ data.item.association }}
              </a>
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}</span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


import { mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BBadge,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { prepareLayoutList } from 'echarts/lib/label/labelLayoutHelper';

export default {
  components: {
    ModelListSelect,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    DatePicker,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BBadge,
    BIconCartPlus,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButtonPrint: true,
      activeCheckBox: 0,
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      typeVenteList: [
        { text: "Transit", value: "Transit" },
        { text: "Local", value: "Local" },
      ],
      imp_options: [],
      // solde: null,
      selectedAssocies_acheteur: null,
      selectedAssociation: null,
      association_options: [],
      valueStart: null,
      valueSearch: "",
      valueEnd: null,
      errorValueStart: "",
      hidden: true,
      isOperating: false,
      hiddenLoader: false,
      selectedStatut: null,
      perPage: 10,
      currentPage: 1,
      allRows: 0,
      filter: "",
      pageOptions: [],
      vehicules: [],
      allVehicules: [],
      sortBy: 'date_arrivee',
      sortDesc: true,
      vehiculesList: [],
      headers: [
        { label: "Statut", key: "statut",sortable: true },
       // { label: "Date Enregistrement", key: "created_at", sortable: true },
        { label: "Date d'arrivée", key: "date_arrivee", sortable: true },
        { label: "N. Manifeste", key: "numero_manif", sortable: true },
       // { label: "Association", key: "association", sortable: true },
        // { label: "compte", key: "manifeste.compte.libelle", sortable: true,},
        { label: "N. Chassis", key: "numero_chassis", sortable: true },
        { label: "Marque", key: "marque", sortable: true },
        { label: "Couleur", key: "couleur", sortable: true },
        { label: "Annee", key: "annee", sortable: true },
        { label: "Total Achat", key: "total_achat", sortable: true },
        { label: "Dépenses", key: "total_dep", sortable: true },
        { label: "P. revient", key: "prix_revient", sortable: true },
        { label: "P. vente", key: "prix_vente", sortable: true },
        
      ],
      excelVehicule: [],
      nameState: null,
      hiddenEmpty: false,
      printVehiculeModal: false,
      editVehiculeModal: false,
      sellVehiculeModal: false,
      blPrintModalErrors: null,
      numero_chassisPrintModalErrors: null,
      montantPrintModalErrors: null,
      numero_chassisEditModalErrors: null,
      marqueEditModalErrors: null,
      couleurEditModalErrors: null,
      anneeEditModalErrors: null,
      client_nameSellModalErrors: null,
      client_phoneSellModalErrors: null,
      client_ifuSellModalErrors: null,
      client_reccmSellModalErrors: null,
      deposant_nameSellModalErrors: null,
      deposant_phoneSellModalErrors: null,
      signataire_nameSellModalErrors: null,
      signataire_phoneSellModalErrors: null,
      type_venteSellModalErrors: null,
      montant_payeSellModalErrors: null,
      printForm: {
        id: 0,
        bl: "",
        numero_chassis: "",
        montant: 0,
      },
      editForm: {
        id: 0,
        numero_chassis: "",
        marque: "",
        couleur: "",
        annee: "",
      },
      sellForm: {
        invoice_type: "Facture normalisée",
      },
      statut_options: [
        { text: "Tout", value: null },
        { text: "En dépôt", value: "en dépôt" },
        { text: "En attente", value: "en attente"},
        { text: "En stock", value: "en stock" },
        { text: "En cours de vente", value: "en cours de vente" },
        { text: "Vendu", value: "vendu" },
        { text: "Livré", value: "livré" },
      ],
      endSellDateFilterValue: null,
      startSellDateFilterValue: null,
      dataFiltered: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "1000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    valeurStock() {
      let valeur = 0;
      let vehicules = this.vehicules;

      if (this.selectedAssociation) {
        vehicules = vehicules.filter(
          (veh) => veh.association == this.selectedAssociation
        );
      }


      vehicules.forEach((element) => {
        if (element.statut == "en stock" || element.statut == "en dépôt") {
          valeur += element.prix_revient;
        }
      });

      return this.$thousandSeparator(valeur ? valeur : 0);
    },

    // This filter is used to calculate the amount of profit about selled cars
    benefice() {
      let valeur = 0;
      let vehicules = this.vehicules;

      if (this.selectedAssociation) {
        vehicules = vehicules.filter(
          (operation) => operation.association == this.selectedAssociation
        );
      }

      if (this.convert(this.startSellDateFilterValue)) {
        vehicules = vehicules.filter(
          (element) =>
            element.date_vente >= this.convert(this.startSellDateFilterValue) &&
            !element.stock_initial &&
            !element.inventorie
        );
      }

      if (this.convert(this.endSellDateFilterValue)) {
        vehicules = vehicules.filter(
          (vehicules) =>
            vehicules.date_vente <= this.convert(this.endSellDateFilterValue) &&
            !vehicules.stock_initialf
        );
      }

      vehicules.forEach((element) => {
        if (
          element.statut == "livré" ||
          element.statut == "vendu" ||
          element.statut == "en cours de vente"
        ) {
          valeur += element.benefice;
        }
      });

      if (!this.startSellDateFilterValue && !this.endSellDateFilterValue)
        return 0;
      return this.$thousandSeparator(valeur ? valeur : 0);
    },
  },
  created() {
    this.getVehicules();
    this.getImportateurs();
    this.getAssociationList();
  },
  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },
    itemRowBackground(item) {
      //return item.stock_initial ? "borderLeft" : "normal";
    },
    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        // let mois = date.getM
        let mois = ("0" + (date.getMonth() + 1)).slice(-2);
        let jour = ("0" + date.getDate()).slice(-2);
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },
    onRowClicked(item, index, event) {
      this.$router.push({
        name: "vehicules-details",
        params: { id: item.id },
      });
    },
    changestatut(item) {
      if (item==null){
        this.vehicules=this.allVehicules;
      }else {
        if (["en stock"].includes(item)){

          this.vehicules = this.allVehicules.filter((value)=>["en stock","en dépôt"].includes(value.statut))

        }else {
          this.vehicules = this.allVehicules.filter((value)=>value.statut.includes(item))
        }
      }

   /*   if (["en stock","en dépôt"].includes(item)){
       this.vehicules = this.vehicules.filter((value)=>value.includes(item))
     }else {

     }*/
    },
    getAssociationList() {
      this.$http
        .get("/associations")
        .then((res) => {
          this.association_options = res.data.data;
        })
        .catch((e) => {
          console.log("erreur associesList:", e.response.data.message);
        });
    },
    filterValue(val) {
      this.filter = " ";
    },
    
    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        if (value >= start) {
          return true;
        } else {
          return false;
        }
        //return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },
    filterFunction(row, DateFilter) {
      // if (this.selectedStatut == 'en stock') {
      //   if (['en stock', 'en dépôt'].indexOf(row.statut) !== -1) return true
      // }
      var start = null;
      var end = null;
      if (this.valueStart) {
        var parts = this.valueStart.split('-')
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (this.valueEnd) {
        var parts = this.valueEnd.split('-')
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      var value = new Date(this.toDate(row.created_at));

      if (
        (this.selectedAssociation != null &&
          this.selectedAssociation != row.association) ||
        ((!row.numero_chassis || row.numero_chassis
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1) &&
          (!row.manifeste.numero_manif || row.manifeste.numero_manif
            .toString()
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1) &&
          (!row.manifeste.association.name || row.manifeste.association.name
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1) &&
          (!row.marque || row.marque.toLowerCase().indexOf(this.valueSearch.toLowerCase()) ==
            -1) &&
          (!row.couleur || row.couleur.toLowerCase().indexOf(this.valueSearch.toLowerCase()) ==
            -1) &&
          (!row.annee || row.annee.toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1) )
      ) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }
    },
    goto(item) {
      window.sessionStorage.setItem(
        "manifeste_data",
        JSON.stringify(item.manifeste)
      );
      this.$router.push({
        name: "manifesteDetails",
      });
    },
    showAssociation(item) {
      this.$router.push({
        name: "associations-show",
        params: {
          id: item.id,
        },
      });
    },
    validationFormClient() {
      return new Promise((resolve, reject) => {
        this.$refs.clientRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormDeposant() {
      return new Promise((resolve, reject) => {
        this.$refs.deposantRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSignataire() {
      return new Promise((resolve, reject) => {
        this.$refs.signataireRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.detailsRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    thousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }
    },

    inputDeposantReset() {
      if (this.activeCheckBox != 1) {
        this.sellForm.deposant_name = "";
        this.sellForm.deposant_phone = "";
      }
    },

    sellVehiculeResetModal() {
      // code
    },

    formSubmitted() {},

    getImportateurs() {
      this.$http
        .get("/show-importateurs")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj = {
              value: element.id,
              text: element.name,
            };

            this.imp_options.push(obj);
          });
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    getVehicules() {
      this.$http
        .get("/vehicules")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          let vehicules = res.data.data;
          this.allVehicules=vehicules;
          //console.log(this.allVehicules)
          vehicules.forEach((element) => {
            element.association = element.manifeste.association.name;
          });

          this.vehicules = vehicules.reverse();
          this.vehiculesList = vehicules.reverse();
          
          this.pricesReview()
          this.vehicules = this.allVehicules.filter((value)=>["en stock","en dépôt"].includes(value.statut))
          this.selectedStatut=this.statut_options[3].value;
          
          this.vehicules.forEach((element) => {
            
            if(element.date_arrivee == null) {
              element.date_arrivee = element.manifeste.date_arrivee
              
            var parts = element.date_arrivee.split("-");
            element.date_arrivee = parts[2]+'-'+parts[1]+'-'+parts[0];
            }
          });


          if (this.vehiculesList.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          
          this.allRows = this.vehicules.length
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e);
        });
    },

    pricesReview() {
      //PRICES PREVIEW

      this.vehicules.forEach((element) => {
        let total_dep = 0;
        let total_dem = 0;
        if (element.maintenances) {
          element.maintenances.forEach((mtnace) => {
            if (mtnace.type != "Paiement démarcheur" && mtnace.type != "Paiement Facture normalisée") {
              total_dep += mtnace.prix;
            } else if(mtnace.type != "Paiement Facture normalisée") {
              total_dem += mtnace.prix;
            }
          });
        }

        if (element.detail_vente) {
          element.caution = element.detail_vente.caution;
        }

        element.total_dep = total_dep;
        element.total_dem = total_dem;

        element.total_achat =
          element.conv_achat +
          element.conv_transport +
          element.conv_frais +
          element.conv_carte_imp;
          

        element.total_dep = total_dep;
        element.total_dem = total_dem;
        element.prix_revient -= total_dem;
        
      });

      // Une fois que c'est fait, on calcule à nouveau les prix de vente des vehicules
      this.vehicules.forEach((element) => {
        element.prix_vente =
          element.prix_vente + element.caution - element.total_dem - element.prix_generation_facture;

        element.benefice = element.prix_vente - element.prix_revient;
      });
      
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.excelVehicule.forEach((element) => {
        obj = {
          "Date ARRIVÉE(S)": element.manifeste.date_arrivee,
          "MARQUE(S)": element.marque,
          "NUMERO CHASSIS(S)": element.numero_chassis,
          "NUMERO MANIFESTE(S)": element.manifeste.numero_manif,
          "COULEUR": element.couleur,
          "ANNEE": element.annee,
          "STATUT(S)": element.statut,
          "PRIX ACHAT": element.prix_achat,
          "DEVISE ACHAT": element.dev_achat,
          "CONV ACHAT": element.conv_achat,
          "PRIX CARTE IMPORTATEUR": element.prix_carte_imp,
          "DEVISE CARTE IMPORTATEUR": element.dev_carte_imp,
          "CONV CARTE IMPORTATEUR": element.conv_carte_imp,
          "PRIX TRANSPORT": element.prix_transport,
          "DEVISE TRANSPORT": element.dev_transport,
          "CONV TRANSPORT": element.conv_transport,
          "FRAIS": element.frais,
          "DEVISE FRAIS": element.dev_frais,
          "CONV FRAIS": element.conv_frais,
          "TOTAL ACHAT": element.total_achat
              ? element.total_achat
              : 0,
          "DEPENSES": element.total_dep
              ? element.total_dep
              : 0,
          "PRIX DE REVIENT": element.prix_revient
              ? element.prix_revient
              : 0,
          "PRIX DE VENTE": element.prix_vente
              ? element.prix_vente
              : 0,
          "DEVISE VENTE": element.dev_vente,
          "BENEFICE": element.benefice
              ? element.benefice
              : 0,
          "CARBURANT": element.carburant,
          "NBRE PORTES": element.nbr_portes,
          "BOITE VITESSE": element.boite_vitesse,
          "PUISSANCE FISCALE": element.puissance_fiscale,
          "KILIMETRAGE": element.kilometrage,
          "CARACTERISTIQUES": element.caracteristiques,
          "STOCK INITAL": element.stock_initial == 0 ? 'NON' : 'OUI',
          "DATE DE SORTIE": element.date_sortie,          
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des véhicules",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.vehicules.forEach((element) => {
        obj = {
          "Date ARRIVÉE(S)": element.manifeste.date_arrivee,
          "MARQUE(S)": element.marque,
          "NUMERO CHASSIS(S)": element.numero_chassis,
          "NUMERO MANIFESTE(S)": element.manifeste.numero_manif,
          "COULEUR": element.couleur,
          "ANNEE": element.annee,
          "STATUT(S)": element.statut,
          "PRIX ACHAT": element.prix_achat,
          "DEVISE ACHAT": element.dev_achat,
          "CONV ACHAT": element.conv_achat,
          "PRIX CARTE IMPORTATEUR": element.prix_carte_imp,
          "DEVISE CARTE IMPORTATEUR": element.dev_carte_imp,
          "CONV CARTE IMPORTATEUR": element.conv_carte_imp,
          "PRIX TRANSPORT": element.prix_transport,
          "DEVISE TRANSPORT": element.dev_transport,
          "CONV TRANSPORT": element.conv_transport,
          "FRAIS": element.frais,
          "DEVISE FRAIS": element.dev_frais,
          "CONV FRAIS": element.conv_frais,
          "TOTAL ACHAT": element.total_achat
              ? element.total_achat
              : 0,
          "DEPENSES": element.total_dep
              ? element.total_dep
              : 0,
          "PRIX DE REVIENT": element.prix_revient
              ? element.prix_revient
              : 0,
          "PRIX DE VENTE": element.prix_vente
              ? element.prix_vente
              : 0,
          "DEVISE VENTE": element.dev_vente,
          "BENEFICE": element.benefice
              ? element.benefice
              : 0,
          "CARBURANT": element.carburant,
          "NBRE PORTES": element.nbr_portes,
          "BOITE VITESSE": element.boite_vitesse,
          "PUISSANCE FISCALE": element.puissance_fiscale,
          "KILIMETRAGE": element.kilometrage,
          "CARACTERISTIQUES": element.caracteristiques,
          "STOCK INITAL": element.stock_initial == 0 ? 'NON' : 'OUI',
          "DATE DE SORTIE": element.date_sortie,   
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des véhicules",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  }
};
</script>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-wizard.scss";
    @import "@core/scss/vue/libs/vue-select.scss";

    .date-picker {
      width: 10rem !important;
    }
    #loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        padding-top: 15%;
        z-index: 1000;
    }

    .b-form-date-controls {
        margin-right: 38%;
    }

    .vue-form-wizard {
        box-shadow: 0px 0px;
        margin-bottom: 0px !important;
    }

    .wizard-card-footer {
        height: 20px;
    }

    .wizard-footer-left .wizard-btn {
        //   color: #27295c !important;
        //   border: 1px solid #27295c !important;
        //   background-color: white !important;
        padding-left: 1px !important;
        padding-top: 1px !important;
        padding-right: 15px !important;
        padding-bottom: 1px !important;
    }

    .wizard-footer-right .wizard-btn {
        color: #27295c !important;
        border: 1px solid #27295c !important;
        background-color: white !important;
        padding-left: 15px !important;
        padding-top: 1px !important;
        padding-right: 0px !important;
        padding-bottom: 1px !important;
    }

    .wizard-card-footer {
        margin-bottom: 20px;
    }

    .borderLeft {
        border-left: 15px solid #4dffff !important;
    }

    #my-table {
        position: relative;
    }

    .hoverSuccess:hover {
        color: #2abe79 !important;
    }

    @media screen and (max-width: 600px) {
        .annuler {
            margin-bottom: 5px;
        }

        .blockSearch {
            width: 350px !important;
        }

        .search {
            width: 250px !important;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 800px) {
        /* .annuler {
                    margin-bottom: 5px;
                } */

        .form {
            margin-left: 10%;
        }

        .formRow .form-group {
            width: 500px;
        }

        .blockForm {
            margin-left: 0px !important;
        }

        .blockSearch {
            width: 80% !important;
        }

        .search {
            width: 250px !important;
            margin-bottom: 10px;
        }

        .BlockbButton {
            padding-right: 0px !important;
            margin-right: 40px !important;
        }

        .container-filter-export {
            margin-left: 0px !important;
            margin-right: 8px;
        }

        .container-export {
            text-align: center;	
        }
    }

    @media screen and (max-width: 1000px) {
        /*  #solde-input {
            margin-left: 20px !important;
        } */

        .form {
            margin-left: 12%;
        }

        .fonction {
            width: 520px;
        }

        .BlockbButton {
            padding-right: 130px !important;
        }
    }
</style>
